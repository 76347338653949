import { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableCustomComponent } from "../common/selltowebtable/SellToWebTableCustomComponent";

import { GlobalTenantSiteComponent } from "./GlobalTenantSiteComponent";
import { GlobalTenantQueryComponent } from "./GlobalTenantQueryComponent";
import { GlobalTenantCreateComponent } from "./GlobalTenantCreateComponent";

export class GlobalTenantRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false,
            isLoadingData: false,
            showEditDialog: false
        };

        this.onSiteToggle = this.onSiteToggle.bind(this);
        this.onCreateTenantClick = this.onCreateTenantClick.bind(this);
        this.onCancelCreateTenantModalClick = this.onCancelCreateTenantModalClick.bind(this);
        this.onOkClick = this.onOkClick.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Tenant";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Global/Tenant", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("GlobalTenantRouteComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.onGetClick();
        }
        catch (error) {
            console.logError(error, "GlobalTenantRouteComponent", "componentDidMount");
        }
    }

    onGetClick() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "GlobalTenantRouteComponent/onGetClick", properties: { logon: this.props.logon } });
            fetch("/GlobalTenant/List", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        // selectedTenant: CommonService.addOrdinalColumn(parsedResponse.table[0].name),
                        paintTable: true,
                        isLoadingData: false
                    }, this.setState({ paintTable: false }));
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalTenant/List", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalTenantRouteComponent", "onGetClick");
                                this.props.onShowAlert("danger", parsedError);
                                })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalTenantRouteComponent", "onGetClick");
                            });
                    }
                });
        } catch (error) {
            console.logError(error, "GlobalTenantRouteComponent", "onGetClick");
        }
    }

    onSiteToggle(rowData) {
        try {
            rowData.showSites = rowData.showSites ? false : true;

            if (!rowData.sites) {
                rowData.showSpinner = true;
                let inputData = {
                    tenantGuid: rowData.guid
                };
                this.props.applicationInsights.trackTrace({ message: "GlobalTenantRouteComponent/onSiteToggle", properties: { ...inputData, logon: this.props.logon } });
                fetch("/GlobalTenant/ListSite", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        rowData.sites = CommonService.addOrdinalColumn(parsedResponse.table);
                        rowData.showSpinner = false;
                        this.setState({
                            paintTable: true
                        }, this.setState({ paintTable: false }));
                    })
                    .catch(notOKResponse => {
                        rowData.showSpinner = false;
                        this.setState({
                            paintTable: true
                        }, this.setState({ paintTable: false }));
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalTenant/ListSite", ...inputData, ...parsedError, logon: this.props.logon } });
                                    console.logError(parsedError, "GlobalTenantRouteComponent", "onSiteToggle");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "GlobalTenantRouteComponent", "onSiteToggle");
                                });
                        }
                    });
            }

            this.setState({
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        } catch (error) {
            console.logError(error, "GlobalTenantRouteComponent", "onSiteToggle");
        }
    }

    onCreateTenantClick() {
        try {
            this.setState({
                showEditDialog: true
            });
        }
        catch (error) {
            console.logError(error, "GlobalTenantRouteComponent", "onCreateTenantClick");
        }
    }

    onCancelCreateTenantModalClick() {
        try {
            this.setState({
                showEditDialog: false
            });
        }
        catch (error) {
            console.logError(error, "GlobalTenantRouteComponent", "onCancelCreateTenantModalClick");
        }
    }

    onOkClick(fromTenantGuid, name, shortName) {
        this.setState({ isLoadingData: true });
        let inputData = JSON.stringify({
            fromTenantGuid: fromTenantGuid,
            tenantName: name,
            shortName: shortName
        });

        this.props.applicationInsights?.trackTrace({ message: "GlobalTenantRouteComponent/onGetClick", properties: { logon: this.props.logon } });
        fetch("/GlobalTenant/Create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: inputData,
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.props.onShowAlert("success", { message: "Tenant created successfully!" }, 4000);
                this.onGetClick();
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalTenant/List", ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "GlobalTenantRouteComponent", "onOkClick");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "GlobalTenantRouteComponent", "onOkClick");
                        });
                }
            });
    }

    render() {
        return <SellToWebAdminPageComponent>
            <div className="admin-flex-item admin-body">
                <GlobalTenantCreateComponent
                    onOkClick={this.onOkClick}
                    tenants={this.state.tableData}
                    showEditDialog={this.state.showEditDialog}
                    onCancelCreateTenantClick={this.onCancelCreateTenantModalClick}
                />
                <GlobalTenantQueryComponent
                    onCreateTenantClick={this.onCreateTenantClick}
                />
                <br />
                <SellToWebTableComponent headerText="Tenant" tableData={this.state.tableData} paintTable={this.state.paintTable}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4], onCopyTableCallBack: this.onCopyTableCallBack }} tablePageSize={CommonService.tablePageSize}
                >
                    <SellToWebTableRowComponent>
                        <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <SellToWebTableColumnComponent headerText="Name" dataColumn="name" sortColumn="name" filterColumn="name" />
                        <SellToWebTableColumnComponent headerText="Short Name" dataColumn="shortName" sortColumn="shortName" filterColumn="shortName" />
                        <SellToWebTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                        <SellToWebTableColumnComponent headerText="Updated Date" dataColumn="updatedDate" sortColumn="updatedDate" filterColumn="updatedDate" dataType="dateTime" />
                    </SellToWebTableRowComponent>
                    <SellToWebTableCustomComponent>
                        <GlobalTenantSiteComponent onSiteToggle={this.onSiteToggle} />
                    </SellToWebTableCustomComponent>
                </SellToWebTableComponent>
            </div>
        </SellToWebAdminPageComponent>;
    }
}